import React from 'react';
import './Farmday.css';


function Farmday() {
    return (
        <>
            <div className="main-farmday">
             <div className="projects-container">
                    <div data-aos="fade-up" className="row-gest-one">
                         {/* Column 1 */}
                         <div className="gest-col-1">
                         <h4 className="row-h4">CASE STUDY</h4>
                         <h1 className="row-gest-h1">Product design for Farm Day, E&#8209;commerce Marketplace</h1>
                        <ul className="my-role">
                        <h4 className="row-h4">MY ROLE</h4>
                        <div className="my-role-list">
                            <li>Creative Direction</li>
                            <li>Product Design</li>
                            <li>Mobile Design</li>
                            <li>Logo Design</li>
                            <li>UX / UI</li>
                            </div>
                                    </ul>
                        <ul className="client"> 
                        <h4 className="row-h4">CLIENT</h4>
                        <li>Farm Day</li></ul>

                        <ul className="year"> 
                        <h4 className="row-h4">YEAR</h4>
                        <li>2024</li></ul>
                                            
                      </div>
                
    
        
    
                    {/* Column 2 */}
                    <div className="gest-col-2">
                        <ul className="list-project-info">
                        <li className="list-project-info-text"><strong>Farm Day</strong> offers new experiences by providing a catalog of fresh eco-friendly products in real-time, linked to markers on a map. This allows customers to place orders and ask questions about the farmers' goods. Farm Day is a mobile app and marketplace where customers can find a better selection of eco-friendly products from local farmers, rather than being limited to products from large corporations in the city.</li>
                        <li className="list-project-info-text">This work received a golden nomination in the <strong>London Design Awards</strong> for User Experience Design (UX)—Best User Interface / Experience. 🎉 </li>
                        <li><a className="footer-link" href="https://thelondondesignawards.com/winner-info.php?id=3446" target="_blank" rel="noopener noreferrer">To learn more about this nomination, you could find here &#8594;</a></li>
                        <br></br>
                        <li><a className="footer-link" href="https://www.behance.net/gallery/208775861/Farm-Day-E-commerce-Marketplace" target="_blank" rel="noopener noreferrer">View this project on Behance &#8594;</a></li>
                      </ul>
                       
                    </div>
                </div>
                <div className="farmday-images">
                <div className="videoWrapper">
                 {/* 
                 <iframe title="Liveshops presentation" data-aos="fade-up" className="project-in-video" src="https://player.vimeo.com/video/467189618?autoplay=0&loop=1&title=0&byline=0&portrait=0" frameborder="0" allowfullscreen></iframe>
                
                */}
                </div>
                <img data-aos="fade-up" className="project-in-img" src={require('../../images/Farmday/1.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/2.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/3.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/4.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/5.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/6.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/7.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/8.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/9.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/10.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/11.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/12.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/13.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/14.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/15.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/16.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/17.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/18.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/19.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/20.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/21.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/22.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/23.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/24.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/25.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/26.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/27.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/28.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/29.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/30.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/31.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/32.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/33.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/34.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/35.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/36.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/37.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/38.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/39.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/40.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/41.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/42.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/43.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/44.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/45.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/46.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/47.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/48.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/49.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/50.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/51.jpg')} alt="Farm Day" />
                <img className="project-in-img" src={require('../../images/Farmday/52.jpg')} alt="Farm Day" />
                </div>
               
                {/* <hr /> */}
            </div>
        </div>
        </>
    );
}

export default Farmday;